import React, { useEffect, useState } from "react";
import axios from "axios";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import Nav from "./Nav";
import Footer from "./Footer";
import "./styles/locator.scss";
import StoreImg from "./images/locator.png";
import MapMarkerImg from "./images/mapMarker2.png";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import MapMarker from "./MapMarker";
//modal
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  "@media(max-width: 600px)": {
    width: "95vw",
    height: "90vh",
  },
  "@media(min-width: 601px) and (max-width: 900px)": {
    width: "90vw",
    maxHeight: "80vh",
  },
};

export default function Locator() {
  document.title = "Speed Queen | Locator";

  const [sites, setSites] = useState([]);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [infoData, setInfoData] = useState({});
  const [currentLink, setCurrentLink] = useState("")
  //modal
  const [open, setOpen] = useState(false);

  const handleOpen = (link) => {
    setCurrentLink(link)
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    setCurrentLink("")
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCA17XufYl3oox1qRT6aXvvM8JqmyvGC1I",
  });

  const containerStyle = {
    width: "65%",
    height: "600px",
  };

  const center2 = {
    lat: 55.90072430176191,
    lng: -4.230517394800327,
  };

  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/speedqueen")
      .then((res) => {
        setSites(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isLoaded]);

  const reset = () => {
    setShowInfoBox(false);
    setInfoData({});
  };

  const infoBoxUpdate = async (sqId) => {
    if (sqId == infoData.locationId) {
      reset();
    } else {
      const idx = sites.findIndex((a) => a.locationId == sqId);
      setInfoData(sites[idx]);
      setShowInfoBox(true);
    }
  };

  return isLoaded ? (
    <main className="locations__main">
      <div className="nav">
        <Nav page="locator" />
      </div>
      <section className="locations_top_section">
        <h2>Our Locations</h2>
      </section>

      <section className="locations_data">
        <div className="tableT">
          <table>
            <thead>
              <th>Find Your Local Speed Queen</th>
            </thead>
            <tbody>
              {sites.map(function (d, i) {
                return (
                  <>
                    <tr
                      key={i}
                      onClick={() => {
                        infoBoxUpdate(d.locationId);
                      }}
                    >
                      <td>
                        <img src={MapMarkerImg} height={"30px"} />
                        <p>
                        {!d.open?<>COMING SOON |</>:null} {d.name} - {d.address.postcode} 
                        </p>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>

        {showInfoBox ? (
          <div id="infoBox">
            <img src={StoreImg} />
            <div className="description">
              <h3>{infoData.name}</h3>
              <p id="siteId">
                <span>{infoData.locationId}</span>
              </p>
              <p>
                {infoData.address.full}, {infoData.address.postcode}
              </p>
              <div>
                {infoData.hours.open == "" ?
                  <>{!infoData.open?<h4>COMING SOON</h4>:<h4>Open <b>24/7</b></h4>}</>
                  :
                  <h4>Open: <b>{infoData.hours.open}</b> - <b>{infoData.hours.close}</b></h4>
                }
                <p>
                  <b>Detergent</b> and fabric{" "}
                  <b>softener are always included</b> in every wash.
                </p>
                <p>
                  <b>Every</b> day the laundry is cleaned and <b>sanitised</b>.
                </p>
                <p>
                  In addition, we use an <b>ozone washing system</b> that
                  ensures that the washing machine is sanitised at every wash,
                  so <b>you can be sure</b> that you are washing your laundry in
                  a <b>machine</b> that is <b>free of bacteria</b>.
                </p>
                <p>
                  <span>See you soon!</span>
                </p>
              </div>
              {/* <br /> */}
              <button
                className="directions"
                onClick={()=>handleOpen(infoData.layoutLink)}
                style={{ marginBottom: "2%" }}
              >
                See Machines
              </button>
              {/* <br /> */}
              <a
                className="directions"
                href={`https://www.google.com/maps/dir/?api=1&destination=${infoData.address.full} ${infoData.address.postcode}`}
                target="_blank"
              >
                <NearMeOutlinedIcon /> Get Directions
              </a>
            </div>
          </div>
        ) : (
          <></>
        )}

        <GoogleMap
          id="google-map"
          mapContainerStyle={containerStyle}
          zoom={9}
          center={center2}
          options={{ streetViewControl: false }}
          onClick={() => {
            reset();
          }}
        >
          {sites.map(function (d, i) {
            return <MapMarker key={i} data={d} update={infoBoxUpdate} />;
          })}
        </GoogleMap>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            See machines and create an alert
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <iframe
              src={currentLink}
              title="Machines"
              style={{maxHeight:"65vh" }}
            />
          </Typography>
        </Box>
      </Modal>
      <Footer />
    </main>
  ) : (
    <></>
  );
}
