import Nav from "./Nav"
import Footer from "./Footer"

export default function Festive() {

    return (
        <main className="faq__main">
            <Nav page="festive" />
            <h2>Festive Support</h2>
            <p className="faq_text_main">
                During the festive period there will be the changes to our customer support service. 
                We have outlined the dates and support available in the table below.
            </p>
            <table>
                <thead>
                    <td>Date</td>
                    <td>Available Support</td>
                </thead>
                <tbody>
                    <tr>
                        <td>23<span>rd</span> December 2024</td>
                        <td>WhatsApp/Email Support only</td>
                    </tr>
                    <tr>
                        <td>24<span>th</span> - 26<span>th</span> December 2024</td>
                        <td>WhatsApp/Email Support only but please expect slower than normal responses.</td>
                    </tr>
                    <tr>
                        <td>27<span>th</span> December 2024</td>
                        <td>Normal Weekday Support</td>
                    </tr>
                    <tr>
                        <td>28<span>th</span> - 29<span>th</span> December 2024</td>
                        <td>Normal Weekend Support</td>
                    </tr>
                    <tr>
                        <td>30<span>th</span> December 2024</td>
                        <td>Normal Weekday Support</td>
                    </tr>
                    <tr>
                        <td>31<span>st</span> December 2024</td>
                        <td>WhatsApp/Email Support only but please expect slower than normal responses.</td>
                    </tr>
                    <tr>
                        <td>1<span>st</span> - 2<span>nd</span> January 2025</td>
                        <td>WhatsApp/Email Support only but please expect slower than normal responses.</td>
                    </tr>
                    <tr>
                        <td>3<span>rd</span> January 2025</td>
                        <td>Normal Support Service Resumes</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <Footer />
        </main>
    )
}